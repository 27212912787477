import React, { useState, useEffect, FC, useCallback } from 'react';
import { ActionButton, Checkbox, FontIcon, PartialTheme, PrimaryButton, ScrollablePane, ScrollbarVisibility, Stack, Sticky, StickyPositionType, TextField, ThemeProvider, TooltipHost } from '@fluentui/react';
import ProgressControl from '../ProgressControl';
import { CombineUrl } from '../../helpers/UrlHelper';
import { DataProvider } from '../../providers/DataProvider';
import { MapTeamsContextToITeamRequest } from '../../helpers/Mapper';
import { useId } from '@fluentui/react-hooks';
import { Constants } from '../../helpers/Constants';
import { IBackendCapabilities, ITeamRequest } from '../../model/model';
import { useTeams } from '../../context/TeamsContext';
import { t } from "i18next";
import { Features } from '../../config/Features';

export enum CheckStatus {
    NotStarted,
    InProgress,
    SuccessfulTrue,
    SuccessfulFalse,
    Failed
}

interface IConfigScreenProps {
    dataProvider: DataProvider;
}

const getStatusText = (status: CheckStatus): string => {
    switch (status) {
        case CheckStatus.InProgress:
            return t('In progress');
        case CheckStatus.SuccessfulTrue:
            return t('OK');
        case CheckStatus.SuccessfulFalse:
            return t('Not set');
        case CheckStatus.Failed:
            return t('Failed');
        case CheckStatus.NotStarted:
        default:
            return t('Not started');
    }
};

const getStatusIcon = (status: CheckStatus): JSX.Element => {
    switch (status) {
        case CheckStatus.SuccessfulTrue:
            return <FontIcon iconName='Completed' style={{ color: "green" }} />;
        case CheckStatus.SuccessfulFalse:
        case CheckStatus.Failed:
            return <FontIcon iconName='ErrorBadge' style={{ color: "red" }} />;
        case CheckStatus.InProgress:
        case CheckStatus.NotStarted:
        default:
            return <FontIcon iconName='ProgressRingDots' />;
    }
};

const ConfigScreen: FC<IConfigScreenProps> = (props) => {
    const { teamsState } = useTeams();
    const { dataProvider } = props;
    const [p360url, setP360url] = useState("");
    const [useInteractiveAuthentication, setUseInteractiveAuthentication] = useState(false);
    const [showInteractiveAuthOption, setShowInteractiveAuthOption] = useState(false);
    const [connectionCheckStatus, setConnectionCheckStatus] = useState<CheckStatus>(CheckStatus.NotStarted);
    const [connectionInfo, setConnectionInfo] = useState("");
    const [permissionsCheckStatus, setPermissionsCheckStatus] = useState<CheckStatus>(CheckStatus.NotStarted);
    const [permissionsCheckInfo, setPermissionsCheckInfo] = useState("");
    const [permissionsSetStatus, setPermissionsSetStatus] = useState<CheckStatus>(CheckStatus.NotStarted);
    const [permissionsSetInfo, setPermissionsSetInfo] = useState("");
    const [versionCheckStatus, setVersionCheckStatus] = useState<CheckStatus>(CheckStatus.NotStarted);
    const [versionCheckInfo, setVersionCheckInfo] = useState("");
    const [serverCapabilities, setServerCapabilities] = useState<IBackendCapabilities>();
    const loading = connectionCheckStatus === CheckStatus.InProgress ||
        permissionsCheckStatus === CheckStatus.InProgress ||
        permissionsSetStatus === CheckStatus.InProgress ||
        versionCheckStatus === CheckStatus.InProgress;

    useEffect(() => {
        dataProvider.MsTeams.initialize().then(async () => {
            const config = await dataProvider.getConfiguration();
            setP360url(config.p360Url || config.default360Url);
            if (Features.EnableInteractiveAuthentication) {
                setUseInteractiveAuthentication(config.useInteractiveAuthentication || config.defaultUseInteractiveAuthentication || config.forceInteractiveAuthentication);
                setShowInteractiveAuthOption(config.showInteractiveAuthentication && config.forceInteractiveAuthentication !== true);
            }
            dataProvider.MsTeams.notifySuccess();
        });
    }, [dataProvider]);

    const checkPermissions = useCallback(async () => {
        setPermissionsCheckStatus(CheckStatus.InProgress);
        dataProvider.P360.checkAppPermissions({ AccessToken: await teamsState.getAccessToken(), TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest }).then((permissionResult) => {
            if (permissionResult.data === true) {
                setPermissionsCheckStatus(CheckStatus.SuccessfulTrue);
            } else {
                setPermissionsCheckStatus(CheckStatus.SuccessfulFalse);
            }
        }).catch(reason => {
            setPermissionsCheckStatus(CheckStatus.Failed);
            setPermissionsCheckInfo(`${t('Could not check app permissions')}: ${reason}. ${t('Details')}: ${reason?.response?.data?.Message}`);
        });
    }, [dataProvider.P360, teamsState]);

    const setPermissionsClickHandler = useCallback(async () => {
        setPermissionsSetStatus(CheckStatus.InProgress);
        dataProvider.P360.setAppPermissions({ AccessToken: await teamsState.getAccessToken(), TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest }).then(() => {
            setPermissionsSetStatus(CheckStatus.SuccessfulTrue);
            setPermissionsCheckStatus(CheckStatus.SuccessfulTrue);
        }).catch(reason => {
            setPermissionsSetStatus(CheckStatus.Failed);
            setPermissionsSetInfo(`${t('Could not set app permissions')}: ${reason}. ${t('Details')}: ${reason?.response?.data?.Message}`);
        });
    }, [dataProvider.P360, teamsState]);

    const checkConnectionClickHandler = useCallback(async () => {
        setPermissionsCheckInfo("");
        setPermissionsSetInfo("");
        setVersionCheckInfo("");
        setConnectionInfo("");
        setPermissionsCheckStatus(CheckStatus.NotStarted);
        setPermissionsSetStatus(CheckStatus.NotStarted);
        setVersionCheckStatus(CheckStatus.NotStarted);
        setConnectionCheckStatus(CheckStatus.InProgress);
        dataProvider.MsTeams.setValidityState(false);

        if (p360url.endsWith('/')) {
            setP360url(p360url.slice(0, -1));
        }

        const interactiveAuthHandler = useInteractiveAuthentication ? async () => dataProvider.authenticateTo360(p360url) : undefined;
        const tokenAuthHandler = useInteractiveAuthentication ? undefined : teamsState.getAccessToken;

        dataProvider.P360.initialize(p360url, tokenAuthHandler, interactiveAuthHandler);
        try {
            await dataProvider.P360.checkConnection();

            setConnectionCheckStatus(CheckStatus.SuccessfulTrue);
            let path = `archive?${Constants.ConfigKeys.P360Url}=${p360url}&${Constants.ConfigKeys.UseInteractiveAuthentication}=${useInteractiveAuthentication}`;
            if (teamsState.userContext?.chat) {
                path = `chatarchive?${Constants.ConfigKeys.P360Url}=${p360url}&${Constants.ConfigKeys.UseInteractiveAuthentication}=${useInteractiveAuthentication}`;
            }
            if (teamsState.userContext?.meeting) {
                path = `meetingarchive?${Constants.ConfigKeys.P360Url}=${p360url}&${Constants.ConfigKeys.UseInteractiveAuthentication}=${useInteractiveAuthentication}`;
            }
            dataProvider.MsTeams.setSettings({
                contentUrl: CombineUrl(window.location.origin, path),
                entityId: process.env.REACT_APP_ENTITY_ID || '',
            }).then(() => {
                dataProvider.MsTeams.setValidityState(true);
            });

            setVersionCheckStatus(CheckStatus.InProgress);
            dataProvider.P360.getConfigurationType().then((cfg) => {
                setVersionCheckStatus(CheckStatus.SuccessfulTrue);
                setServerCapabilities(cfg.data);

                if (cfg.data?.Capabilities?.includes(Constants.Capabilities.AppPermissionsEndpoints) && teamsState.userContext?.channel && !teamsState.userContext?.meeting) {
                    checkPermissions();
                }

            }).catch(reason => {
                setVersionCheckInfo(`${t('Could not check version')}: ${reason}. ${t('Details')}: ${reason?.response?.data?.Message}`);
                setVersionCheckStatus(CheckStatus.Failed);
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (reason: any) {
            setConnectionCheckStatus(CheckStatus.Failed);
            setConnectionInfo(`${t('Could not check connection')}: ${reason}. ${t('Details')}: ${reason?.response?.data?.Message}`);
        }
    }, [checkPermissions, dataProvider, p360url, teamsState.getAccessToken, teamsState.userContext?.channel, teamsState.userContext?.chat, teamsState.userContext?.meeting, useInteractiveAuthentication]);

    const urlOnChangeHandler = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setP360url((event.target as HTMLInputElement).value);
        dataProvider.MsTeams.setValidityState(false);
    }, [dataProvider.MsTeams]);

    const lightTheme: PartialTheme = {
        semanticColors: {
            bodyBackground: 'white',
            bodyText: 'black'
        }
    };

    const setAppPermissionsTooltipId = useId("setAppPermissionsTooltipId");
    return <ThemeProvider applyTo='body' theme={lightTheme}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ margin: "10px" }}>
            <Sticky stickyPosition={StickyPositionType.Header}><ProgressControl visible={loading} label={t('Verifying connection...')} /></Sticky>
            <Sticky stickyPosition={StickyPositionType.Header}><h2>{t('Configuration')}</h2></Sticky>
            <Stack>
                <Stack horizontal verticalAlign="end">
                    <Stack.Item grow>
                        <TextField type="url" label={t('360° URL')} placeholder="https://example.360online.com" required value={p360url} onChange={urlOnChangeHandler} />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton text={t('Check connection')} onClick={checkConnectionClickHandler} disabled={loading || !p360url || p360url?.length < 10} />
                    </Stack.Item>
                </Stack>
                {showInteractiveAuthOption &&
                    <Stack style={{ marginTop: 10 }}>
                        <Stack.Item>
                            <Checkbox label={t('Use interactive authentication')} checked={useInteractiveAuthentication} onChange={(e, checked) => setUseInteractiveAuthentication(!!checked)} />
                        </Stack.Item>
                    </Stack>
                }
                <Stack>
                    {connectionCheckStatus !== CheckStatus.NotStarted && <p>{getStatusIcon(connectionCheckStatus)} {t('Checking connection...')}{getStatusText(connectionCheckStatus)}</p>}
                    {connectionInfo && <p>{connectionInfo}</p>}
                    {versionCheckStatus !== CheckStatus.NotStarted && <p>{getStatusIcon(versionCheckStatus)} {t('Checking server version...')}{getStatusText(versionCheckStatus)} {serverCapabilities?.Information?.Version}</p>}
                    {versionCheckInfo && <p>{versionCheckInfo}</p>}
                    {
                        teamsState.userContext?.channel && <div>
                            {permissionsCheckStatus !== CheckStatus.NotStarted && <p>{getStatusIcon(permissionsCheckStatus)} {t('Checking app permissions...')}{getStatusText(permissionsCheckStatus)}</p>}
                            {permissionsCheckInfo && <p>{permissionsCheckInfo}</p>}
                            {permissionsCheckStatus === CheckStatus.Failed && <ActionButton text={t('Re-check app permissions')} onClick={checkPermissions} />}
                            {
                                connectionCheckStatus === CheckStatus.SuccessfulTrue &&
                                permissionsCheckStatus === CheckStatus.SuccessfulFalse &&
                                <span><TooltipHost content={t('Try to set permissions on the SharePoint site that is connected to the channel. This requires that you have admin permissions on the site.')} id={setAppPermissionsTooltipId}>
                                    <ActionButton text={t('Set permissions')} onClick={setPermissionsClickHandler} aria-describedby={setAppPermissionsTooltipId} />
                                </TooltipHost></span>
                            }
                            {permissionsSetStatus !== CheckStatus.NotStarted && <p>{getStatusIcon(permissionsSetStatus)} {t('Trying to set app permissions...')}{getStatusText(permissionsSetStatus)}</p>}
                            {permissionsSetInfo && <p>{permissionsSetInfo}</p>}
                        </div>
                    }
                </Stack>
            </Stack>
        </ScrollablePane>
    </ThemeProvider>;
};

export default ConfigScreen;