import { IRenderFunction, IPanelProps, Stack, Panel, PanelType, ChoiceGroup, IChoiceGroupOption, TextField, DefaultButton, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EnumDocument, IFileListItem, ImportArchiveCreateDocumentParameter, ISharepointFileResponse, ITeamRequest, ITeamServiceRequest } from "../../model/model";
import { verticalGapStackTokens, importPanelSubheaderAlignmentStyles, viewSubHeaderContentAlignmentStyles } from "../../styles/MergeStyleSets";
import { Constants } from "../../helpers/Constants";
import { RenderPanelHeader } from "../common/RenderPanelHeader";
import { AxiosResponse } from "axios";
import { useTeams } from "../../context/TeamsContext";
import { MapTeamsContextToITeamRequest } from "../../helpers/Mapper";
import { ICommonPropsArchivePanel } from "../ICommonProps";
import { FileType } from "../../helpers/FileType";
import { useP360 } from "../../context/P360Context";
import { getDocumentRegistrationViewUrl, openDocumentRegistrationPage } from "../../helpers/DocumentRegistrationHelper";
import { useBoolean } from "@fluentui/react-hooks";
import { useFiles } from "../../context/FilesContext";
import DocumentCreatedToImportArchiveMessage from "../common/DocumentCreatedToImportArchiveMessage";

interface IImportArchiveContainerProps extends ICommonPropsArchivePanel, IPanelProps {
    isOpen: boolean,
    selectedFiles: IFileListItem[],
    clearSelectedFiles?: () => void
}

export enum ImportArchiveOption {
    ArchiveAsSingleDocument = 'ArchiveAsSingleDocument',
    SendTo360 = 'SendTo360',
}

const ImportArchiveContainer: FC<IImportArchiveContainerProps> = (props) => {
    const { t } = useTranslation();
    const { teamsState } = useTeams();
    const { refreshArchivingStatusForFiles } = useFiles();
    const { p360State } = useP360();
    const { dataProvider, selectedFiles, clearSelectedFiles, isOpen, onDismiss } = props;

    const [selectedImportArchiveOption, setSelectedImportArchiveOption] = useState<ImportArchiveOption>(ImportArchiveOption.ArchiveAsSingleDocument);
    const [selectedSendTo360Option, setSelectedSendTo360Option] = useState<EnumDocument>(EnumDocument.CreateSingleDocument);
    const [documentTitle, setDocumentTitle] = useState<string>("");
    const [documentCreationSuccess, setDocumentCreationSuccess] = useState<boolean>(false);
    const [documentRegUrl, setDocumentRegUrl] = useState<string>("");
    const [isPrimaryButtonDisabled, { setTrue: disablePrimaryButton, setFalse: enablePrimaryButton }] = useBoolean(false);
    const [showDocumentTitleError, setShowDocumentTitleError] = useState(false);
    const requiredError = t('Required');
    const [isSaving, setIsSaving] = useBoolean(false);

    const importArchiveOptions: IChoiceGroupOption[] = [
        { key: ImportArchiveOption.ArchiveAsSingleDocument.toString(), text: t('Start registration for this document') },
        { key: ImportArchiveOption.SendTo360.toString(), text: t('Archive directly') }
    ];

    const defaultDocumentTitle = useCallback(() => {
        if (selectedFiles && selectedFiles.length > 0) {
            setDocumentTitle(FileType.removeExtensionFromFileName(selectedFiles[0].fileName));
        }
    }, [selectedFiles]);

    useEffect(() => {
        defaultDocumentTitle();
    }, [defaultDocumentTitle, selectedFiles]);

    const onChangeImportArchiveOptions = useCallback((_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSelectedImportArchiveOption(option?.key as ImportArchiveOption);
        enablePrimaryButton();
        defaultDocumentTitle();
        setShowDocumentTitleError(false);
    }, [defaultDocumentTitle, enablePrimaryButton]);

    const onChangeSendTo360Options = useCallback((_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSelectedSendTo360Option(option?.key as EnumDocument);
        enablePrimaryButton();
        defaultDocumentTitle();
        setShowDocumentTitleError(false);
    }, [defaultDocumentTitle, enablePrimaryButton]);

    const sendTo360View = useCallback(() => {
        const sendTo360Options: IChoiceGroupOption[] = [
            { key: EnumDocument.CreateSingleDocument.toString(), text: t('Create one single document for all files') },
            { key: EnumDocument.CreateMultipleDocument.toString(), text: t('Create separate document for each file') }
        ];

        return <div style={{ margin: '10px 0px 0px 25px' }}>
            <ChoiceGroup
                selectedKey={selectedSendTo360Option}
                options={sendTo360Options}
                onChange={onChangeSendTo360Options}
                required={true}
                disabled={documentCreationSuccess} />
        </div>;
    }, [documentCreationSuccess, onChangeSendTo360Options, selectedSendTo360Option, t]);

    const onDocumentTitleChange = useCallback((_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setDocumentTitle(newValue as string);
        if (newValue?.trim() === "") {
            setShowDocumentTitleError(true);
            disablePrimaryButton();
        }
        else {
            setShowDocumentTitleError(false);
            enablePrimaryButton();
        }
    }, [disablePrimaryButton, enablePrimaryButton]);

    const closePanel = useCallback(() => {
        refreshArchivingStatusForFiles(props.selectedFiles);
        setDocumentCreationSuccess(false);
        setShowDocumentTitleError(false);
        setDocumentRegUrl("");
        setDocumentTitle("");
        enablePrimaryButton();
        defaultDocumentTitle();
        clearSelectedFiles?.();
        setSelectedImportArchiveOption(ImportArchiveOption.ArchiveAsSingleDocument);
        setIsSaving.setFalse();
        onDismiss && onDismiss();
    }, [clearSelectedFiles, defaultDocumentTitle, enablePrimaryButton, onDismiss, props.selectedFiles, refreshArchivingStatusForFiles, setIsSaving]);

    const getErrorMessage = (showError: boolean, errorMessage?: string): string | undefined => {
        return showError ? errorMessage : undefined;
    };
    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (_props, _defaultRender) => (
            <RenderPanelHeader closePanel={closePanel} />
        ), [closePanel]);
    const primaryButtonText = useCallback(() => {
        return selectedImportArchiveOption === ImportArchiveOption.ArchiveAsSingleDocument ? t('Create document in 360') : t('Send to 360');
    }, [selectedImportArchiveOption, t]);

    const processFiles = useCallback(async () => {
        const promises: Promise<AxiosResponse<ISharepointFileResponse>>[] = [];
        const request: ITeamServiceRequest = {
            accessToken: await teamsState.getAccessToken(),
            teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest
        };
        selectedFiles.forEach((f) => {
            request.driveId = f.fileDriveId as string;
            request.fileId = f.fileId as string;
            request.fileName = f.fileName as string;
            promises.push(dataProvider?.P360.downloadAndCreateFileOnly(request) as Promise<AxiosResponse<ISharepointFileResponse>>);
        });
        const parameter: ImportArchiveCreateDocumentParameter = {
            documentTitle: documentTitle,
            files: []
        };
        const results = await Promise.allSettled(promises);
        results.forEach((result) => {
            if (result.status === "fulfilled" && result.value.data) {
                parameter.files.push({ fileId: result.value.data.Id, driveId: result.value.data.DriveId, fileName: result.value.data.File.Name, fileToken: result.value.data.ContentUrl as string });
            }
        });
        return parameter;
    }, [dataProvider?.P360, documentTitle, selectedFiles, teamsState]);

    const onPrimaryClick = useCallback(async () => {
        setIsSaving.setTrue();
        disablePrimaryButton();
        const parameter = await processFiles();
        parameter.documentTitle = FileType.removeExtensionFromFileName(parameter.files[0].fileName);
        if (selectedImportArchiveOption === ImportArchiveOption.ArchiveAsSingleDocument) {
            const result = await dataProvider?.P360.createDocumentForImportArchive(parameter);
            const registerUrl = getDocumentRegistrationViewUrl(p360State.p360Url, result?.data?.Recno as number, Constants.RegistrationViewsType.BIF);
            setDocumentRegUrl(registerUrl);
            setDocumentCreationSuccess(true);
            openDocumentRegistrationPage(registerUrl);
            setIsSaving.setFalse();
        }
        else if (selectedImportArchiveOption === ImportArchiveOption.SendTo360) {
            if (selectedSendTo360Option === EnumDocument.CreateSingleDocument) {
                parameter.documentTitle = documentTitle;
                await dataProvider?.P360.createDocumentForImportArchive(parameter);
            }
            else {
                await Promise.all(parameter.files.map(async item => {
                    const singleFileParameter: ImportArchiveCreateDocumentParameter = {
                        ...parameter,
                        documentTitle: FileType.removeExtensionFromFileName(item.fileName),
                        files: [item]
                    };
                    await dataProvider?.P360.createDocumentForImportArchive(singleFileParameter);
                }));
            }
            closePanel();
        }

    }, [closePanel, dataProvider?.P360, disablePrimaryButton, documentTitle, p360State.p360Url, processFiles, selectedImportArchiveOption, selectedSendTo360Option, setIsSaving]);

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (_props, _defaultRender) => (
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text={t('Close')} disabled={isSaving} onClick={closePanel} />
                <PrimaryButton disabled={isPrimaryButtonDisabled || isSaving || documentCreationSuccess} text={primaryButtonText()} onClick={onPrimaryClick} />
            </div>
        ), [closePanel, documentCreationSuccess, isPrimaryButtonDisabled, isSaving, onPrimaryClick, primaryButtonText, t]);

    return (
        <Panel
            type={PanelType.custom}
            customWidth="418px"
            isOpen={isOpen}
            onDismiss={closePanel}
            closeButtonAriaLabel={t('Close')}
            isFooterAtBottom={true}
            onRenderNavigation={onRenderNavigationContent}
            onRenderFooterContent={onRenderFooterContent}>
            {isSaving && <div><ProgressIndicator /></div>}
            <Stack tokens={verticalGapStackTokens}>
                <Stack.Item align="auto" styles={importPanelSubheaderAlignmentStyles}>
                    <span style={viewSubHeaderContentAlignmentStyles}>{t('Import archiving')}</span>
                </Stack.Item>
            </Stack>
            <ChoiceGroup
                selectedKey={selectedImportArchiveOption}
                options={importArchiveOptions}
                onChange={onChangeImportArchiveOptions}
                required={true}
                disabled={isSaving || documentCreationSuccess} />
            {
                selectedImportArchiveOption === ImportArchiveOption.SendTo360 ?
                    sendTo360View()
                    : <></>
            }
            <div style={{ margin: ' 10px 0px 0px 25px' }}>
                {
                    (selectedImportArchiveOption === ImportArchiveOption.SendTo360 &&
                        selectedSendTo360Option === EnumDocument.CreateSingleDocument) ?
                        <TextField label={t('Document title')}
                            value={documentTitle}
                            maxLength={Constants.DocumentTitleMaxLength}
                            required
                            multiline
                            resizable={false}
                            disabled={isSaving || documentCreationSuccess}
                            onChange={onDocumentTitleChange}
                            errorMessage={getErrorMessage(showDocumentTitleError, requiredError)} />
                        : <></>
                }
            </div>
            {documentCreationSuccess && <DocumentCreatedToImportArchiveMessage documentRegUrl={documentRegUrl} />}
        </Panel>
    );
};

export default ImportArchiveContainer;