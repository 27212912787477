import { Constants } from "./Constants";
import { CombineUrl } from "./UrlHelper";

/** Returns the document registration view full url based on configuration. */
export function getDocumentRegistrationViewUrl(p360Url: string, recno: number, viewType: string): string {
    let registrationRelativeUrl;
    switch (viewType) {
        case Constants.RegistrationViewsType.AI:
            registrationRelativeUrl = Constants.RegistrationViewsUrl.AI.RelativeUrl;
            break;
        case Constants.RegistrationViewsType.PnB:
            registrationRelativeUrl = Constants.RegistrationViewsUrl.PnB.RelativeUrl;
            break;
        case Constants.RegistrationViewsType.BIF:
        default:
            registrationRelativeUrl = Constants.RegistrationViewsUrl.BIF.RelativeUrl;
            break;
    }

    return CombineUrl(p360Url, registrationRelativeUrl + recno);
}

/** Opens the url in popup if BIF view, otherwise window.open with browser default behaviour. */
export async function openDocumentRegistrationPage(registrationFullUrl: string) {
    if (!registrationFullUrl) { return; }
    // BIF view should be opened in popup
    const usePopup = registrationFullUrl.indexOf(Constants.RegistrationViewsUrl.BIF.RelativeUrl) > 0;

    if (usePopup) {
        const width = 800;
        const height = 920;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        window.open(registrationFullUrl, "_blank", `resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, noopener, width=${width}, height=${height}, left=${left}, top=${top}`);
    } else {
        window.open(registrationFullUrl);
    }
}