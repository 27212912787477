import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { FC } from "react";
import i18n from '../../i18n';
import { useP360 } from "../../context/P360Context";
import { Constants } from "../../helpers/Constants";
import HelpIcon from '../../images/help.svg';
import { IFileListItem } from "../../model/model";
import { formatVersionForHelpUrl } from "../../helpers/Mapper";

export enum EnumCommonAction {
    None = 'None',
    Import = 'Import',
    ClearSelection = 'ClearSelection'
}

export enum EnumArchiveAction {
    FileArchiving = 'FileArchiving',
    FolderConnection = 'FolderConnection',
    ChannelArchiving = 'ChannelArchiving',
    ImportArchiving = 'ImportArchiving'
}

export type EnumCommandBarAction = EnumCommonAction | EnumArchiveAction;

interface ICommandBarContainerProps {
    selectedFiles?: IFileListItem[];
    handleAction: (action: EnumCommandBarAction) => void;
}

const CommandBarContainer: FC<ICommandBarContainerProps> = (props) => {
    const { p360State } = useP360();
    const { selectedFiles } = props;
    const hasSelectedItems = selectedFiles && selectedFiles.length > 0;
    const hasSelectedFilesOnly = selectedFiles && selectedFiles.length > 0 && selectedFiles.every(f => !f.isFolder);
    const hasSelectedFoldersOnly = selectedFiles && selectedFiles.length > 0 && selectedFiles.every(f => f.isFolder);
    const automaticArchivingPossible = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.AutomaticArchiving) === true;

    const getFolderConnectionCommand = (): ICommandBarItemProps => {
        const folderArchivingIcon = automaticArchivingPossible ? 'FabricSyncFolder' : 'Add';
        const folderArchivingText = automaticArchivingPossible ? i18n.t('Folder synchronization') : i18n.t('Add 360 case');
        let folderArchivingTooltip;
        if (automaticArchivingPossible) {
            folderArchivingTooltip = hasSelectedFoldersOnly
                ? i18n.t('Connect folder(s) to a case in 360° for automatic synchronization')
                : i18n.t('Select folder(s) to configure automatic synchronization');
        } else {
            folderArchivingTooltip = i18n.t('Connect a folder to a case in 360° for easy archiving');
        }

        return {
            key: EnumArchiveAction.FolderConnection.toString(),
            text: folderArchivingText,
            iconProps: { iconName: folderArchivingIcon },
            disabled: !hasSelectedFoldersOnly,
            title: folderArchivingTooltip,
            onClick: () => {
                props.handleAction(EnumArchiveAction.FolderConnection);
            }
        };
    };

    const getFileArchivingCommand = (): ICommandBarItemProps => {
        const fileArchivingIcon = automaticArchivingPossible ? 'Upload' : 'Refresh';
        const fileArchivingText = automaticArchivingPossible ? i18n.t('Archive file(s)') : i18n.t('Archive to 360');
        const fileArchivingTooltip = hasSelectedFilesOnly ? undefined : i18n.t('Select file(s) to enable archiving');

        return {
            key: EnumArchiveAction.FileArchiving.toString(),
            text: fileArchivingText,
            disabled: !hasSelectedFilesOnly,
            iconProps: { iconName: fileArchivingIcon },
            title: fileArchivingTooltip,
            onClick: () => {
                if (p360State.backendCapabilities?.Configuration.ArchivingMethod === Constants.ArchivingMethod.ForceArchivingByImportArchive) {
                    props.handleAction(EnumArchiveAction.ImportArchiving);
                }
                else
                    props.handleAction(EnumArchiveAction.FileArchiving);
            }
        };
    };

    const getImportCommand = (): ICommandBarItemProps | null => {
        if (p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.Get360DocumentsWithFiles)
            && p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.ImportFiles)) {
            const fileImportText = automaticArchivingPossible ? i18n.t('Import files') : i18n.t('360° File Importer');
            return {
                key: EnumCommonAction.Import.toString(),
                text: fileImportText,
                disabled: hasSelectedItems && selectedFiles.filter(f => f.isFolder).length > 1,
                iconProps: { iconName: 'download' },
                title: i18n.t('Import files from 360'),
                onClick: () => {
                    props.handleAction(EnumCommonAction.Import);
                }
            };
        }
        return null;
    };

    const getChannelSyncCommand = (): ICommandBarItemProps | null => {
        if (automaticArchivingPossible) {
            return {
                key: EnumArchiveAction.ChannelArchiving.toString(),
                text: i18n.t('Channel synchronization'),
                iconProps: { iconName: 'SyncOccurence' },
                disabled: false,
                title: i18n.t('Configure automatic synchronization for channel'),
                onClick: () => {
                    props.handleAction(EnumArchiveAction.ChannelArchiving);
                }
            };
        }
        return null;
    };

    const commandItems: ICommandBarItemProps[] = [
        getFolderConnectionCommand(),
        getFileArchivingCommand(),
        getImportCommand(),
        getChannelSyncCommand()
    ].filter(item => item !== null) as ICommandBarItemProps[];

    const farCommandItems: ICommandBarItemProps[] = [
        {
            key: 'helpMenu',
            iconProps:
            {
                imageProps: {
                    src: HelpIcon,
                    width: 16,
                    height: 16
                }
            },
            onClick: () => {
                window.open(i18n.t('HelpPageUrl', { p360version: formatVersionForHelpUrl(p360State.backendCapabilities?.Information?.Version) }), "_blank");
            }
        }
    ];

    if (hasSelectedItems) {
        const fileSelectionKey = 'fileSelection';
        const fileSelectionCommandItemIndex = farCommandItems.findIndex(f => f.key === fileSelectionKey);
        if (fileSelectionCommandItemIndex !== -1) {
            farCommandItems[fileSelectionCommandItemIndex].text = `${selectedFiles.length} ${i18n.t('Selected')}`;
        }
        else {
            farCommandItems.push(
                {
                    key: fileSelectionKey,
                    text: `${selectedFiles.length} ${i18n.t('Selected')}`,
                    iconProps: { iconName: 'cancel' },
                    title: i18n.t('Clear selection'),
                    onClick: () => {
                        props.handleAction(EnumCommonAction.ClearSelection);
                    }
                }
            );
        }
        farCommandItems.reverse();
    }
    return <CommandBar
        items={commandItems}
        farItems={farCommandItems}
        ariaLabel={i18n.t("Use left and right arrow keys to navigate between commands")}
    />;
};

export default CommandBarContainer;