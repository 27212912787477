import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { ChoiceGroup, IChoiceGroupOption, TextField } from "@fluentui/react";
import { t } from "i18next";
import DocumentCreatedToImportArchiveMessage from "../../common/DocumentCreatedToImportArchiveMessage";
import { ImportArchiveOption } from "../../rightPanel/ImportArchiveContainer";
import { Constants } from "../../../helpers/Constants";
import { classNames } from "../../../styles/MergeStyleSets";
import { useMessages } from "../../../context/MessagesContext";
import { MessagesActionType } from "../../../context/MessagesReducer";

export interface IMeetingImportArchiveViewProps {
    documentRegUrl: string;
    documentCreationSuccess?: boolean;
    isSaving?: boolean;
}

export interface IMeetingImportArchiveViewRef {
    validate(): boolean;
}
export const MeetingImportArchiveView = forwardRef<IMeetingImportArchiveViewRef, IMeetingImportArchiveViewProps>((props, ref) => {
    const { messageState, dispatch } = useMessages();
    const [selectedImportArchiveOption, setSelectedImportArchiveOption] = useState<ImportArchiveOption>(ImportArchiveOption.ArchiveAsSingleDocument);
    const [showDocumentTitleError, setShowDocumentTitleError] = useState(false);
    const requiredError = t('Required');
    const importArchiveOptions: IChoiceGroupOption[] = [
        { key: ImportArchiveOption.ArchiveAsSingleDocument.toString(), text: t('Start registration for this document') },
        { key: ImportArchiveOption.SendTo360.toString(), text: t('Archive directly') }
    ];

    const onChangeImportArchiveOptions = useCallback((_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSelectedImportArchiveOption(option?.key as ImportArchiveOption);
        dispatch({
            type: MessagesActionType.ImportArchive, payload: {
                importArchive: {
                    ...messageState.importArchive,
                    registrationMethod: option?.key as ImportArchiveOption
                }
            }
        });
    }, [dispatch, messageState.importArchive]);

    const onDocumentTitleChange = useCallback((_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setShowDocumentTitleError(newValue?.trim() === "");
        dispatch({
            type: MessagesActionType.ImportArchive, payload: {
                importArchive: {
                    ...messageState.importArchive,
                    documentTitle: newValue as string
                }
            }
        });
    }, [dispatch, messageState.importArchive]);

    const getErrorMessage = (showError: boolean, errorMessage?: string): string | undefined => {
        return showError ? errorMessage : undefined;
    };
    useImperativeHandle(ref, () => ({
        validate: () => {
            if (!messageState.importArchive.documentTitle) {
                setShowDocumentTitleError(true);
                return false;
            }
            return true;
        }
    }));


    return <div className={classNames.messageCaseViewContainer}>
        <div className={classNames.messageCaseViewHeader}>{t('Import archiving')}</div>
        <div style={{ marginTop: '10px' }}>
            <TextField label={t('Document title')}
                value={messageState.importArchive.documentTitle}
                maxLength={Constants.DocumentTitleMaxLength}
                required
                multiline
                resizable={false}
                onChange={onDocumentTitleChange}
                disabled={props.isSaving || props.documentCreationSuccess}
                errorMessage={getErrorMessage(showDocumentTitleError, requiredError)} />
            <ChoiceGroup
                selectedKey={selectedImportArchiveOption}
                options={importArchiveOptions}
                onChange={onChangeImportArchiveOptions}
                required={true}
                disabled={props.isSaving || props.documentCreationSuccess} />
            {props.documentCreationSuccess && <DocumentCreatedToImportArchiveMessage documentRegUrl={props.documentRegUrl} />}
        </div>
    </div>;
});

export default MeetingImportArchiveView;