import { ScrollablePane, ScrollbarVisibility, Stack, Sticky, StickyPositionType, ThemeProvider } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";
import ErrorNotification from "../error/ErrorNotification";
import CommandBarContainer, { EnumArchiveAction, EnumCommandBarAction, EnumCommonAction } from "./CommandBarContainer";
import BreadcrumbContainer from "./BreadcrumbContainer";
import FileList from "./FileList";
import ProgressContainer from "./ProgressContainer";
import ImportPanelContainer from "../rightPanel/ImportPanelContainer";
import { DataProvider } from "../../providers/DataProvider";
import { IFileListItem } from "../../model/model";
import ArchivePanelContainer from "../rightPanel/ArchivePanelContainer";
import { classNames } from "../../styles/MergeStyleSets";
import { useApiErrorHandler } from "../../context/ApiErrorHandler";
import { Features } from "../../config/Features";
import { useP360 } from "../../context/P360Context";
import { Constants } from "../../helpers/Constants";
import ChannelArchivingTagContainer from "./ChannelArchivingTagContainer";
import ChannelAutomaticArchivingInfo from "./ChannelAutomaticArchivingInfo";
import ImportArchiveContainer from "../rightPanel/ImportArchiveContainer";

interface IArchiveScreenLayoutProps {
    dataProvider: DataProvider;
}

const ArchiveScreenLayout: FC<IArchiveScreenLayoutProps> = (props) => {
    const [currentAction, setCurrentAction] = useState<EnumCommandBarAction>(EnumCommonAction.None);

    const [selectedFiles, setSelectedFiles] = useState<IFileListItem[]>([]);

    const { error } = useApiErrorHandler();
    const { p360State } = useP360();

    const closePanels = useCallback(() => {
        setCurrentAction(EnumCommonAction.None);
    }, []);

    const clearSelection = useCallback(() => {
        setSelectedFiles([]);
        setCurrentAction(EnumCommonAction.None);
    }, []);

    const isArchiveAction = useCallback(() => {
        return currentAction === EnumArchiveAction.FileArchiving
            || currentAction === EnumArchiveAction.FolderConnection
            || currentAction === EnumArchiveAction.ChannelArchiving;
    }, [currentAction]);

    const handleAction = useCallback((action: EnumCommandBarAction) => {
        setCurrentAction(action);
    }, []);

    const autoArchivingEnabled = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.AutomaticArchiving) === true;

    useEffect(() => {
        if (currentAction === EnumCommonAction.ClearSelection) {
            clearSelection();
        }
    }, [clearSelection, currentAction]);

    useEffect(() => {
        if (error) {
            closePanels();
        }
    }, [closePanels, error]);

    return <ThemeProvider className={classNames.wrapper}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ overflowX: "hidden" }}>
            <Sticky stickyPosition={StickyPositionType.Header}>
                <ErrorNotification />
                <CommandBarContainer
                    selectedFiles={selectedFiles}
                    handleAction={handleAction} />
                {!Features.UseNewLoading && <ProgressContainer />}
                {autoArchivingEnabled
                    ? <>
                        <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }}>
                            <Stack.Item grow>
                                <BreadcrumbContainer clearSelectedFiles={clearSelection} />
                            </Stack.Item>
                            <Stack.Item>
                                <ChannelArchivingTagContainer />
                            </Stack.Item>
                        </Stack>
                        <ChannelAutomaticArchivingInfo />
                    </>
                    : <BreadcrumbContainer clearSelectedFiles={clearSelection} />}
            </Sticky>
            <FileList
                dataProvider={props.dataProvider}
                setSelectedFiles={setSelectedFiles}
                selectedFiles={selectedFiles} />
        </ScrollablePane>
        <ArchivePanelContainer
            dataProvider={props.dataProvider}
            selectedFiles={selectedFiles}
            clearSelectedFiles={clearSelection}
            isOpen={isArchiveAction()}
            onDismiss={closePanels}
            isFileArchiveAction={currentAction === EnumArchiveAction.FileArchiving}
            archiveAction={currentAction as EnumArchiveAction} />
        <ImportPanelContainer
            dataProvider={props.dataProvider}
            selectedFiles={selectedFiles}
            isOpen={currentAction === EnumCommonAction.Import}
            onDismiss={closePanels} />
        <ImportArchiveContainer
            dataProvider={props.dataProvider}
            selectedFiles={selectedFiles}
            clearSelectedFiles={clearSelection}
            isOpen={currentAction === EnumArchiveAction.ImportArchiving}
            isFileArchiveAction={currentAction === EnumArchiveAction.ImportArchiving}
            onDismiss={closePanels} />
    </ThemeProvider>;
};

export default ArchiveScreenLayout;