import { FC, useCallback, useRef, useState } from 'react';
import { IPanelProps, IRenderFunction, Panel, PanelType, ProgressIndicator } from '@fluentui/react';
import { t } from "i18next";
import { ICommonProps } from '../../ICommonProps';
import { EnumCase, EnumSearchCriteria, IArchiveMeetingResult, IMessage } from '../../../model/model';
import { useMessages } from '../../../context/MessagesContext';
import { IMessageCaseViewRef, MessageCaseView } from '../../chatArchiving/panel/MessageCaseView';
import { IMessageNewDocumentViewRef, MessageNewDocumentView } from '../../chatArchiving/panel/MessageNewDocumentView';
import { StepWizard } from '../../chatArchiving/StepWizard';
import MeetingSelectionView from './MeetingSelectionView';
import { RenderPanelHeader } from '../../common/RenderPanelHeader';
import { RenderPanelFooter } from '../../common/RenderPanelFooter';
import { MessagesActionType } from '../../../context/MessagesReducer';
import MeetingImportArchiveView, { IMeetingImportArchiveViewRef } from './MeetingImportArchiveView';
import { ImportArchiveOption } from '../../rightPanel/ImportArchiveContainer';
import { getDocumentRegistrationViewUrl, openDocumentRegistrationPage } from '../../../helpers/DocumentRegistrationHelper';
import { useP360 } from '../../../context/P360Context';
import { Constants } from '../../../helpers/Constants';

interface IMeetingArchivePanelProps extends ICommonProps, IPanelProps {
    isOpen: boolean;
    selectedMeetings?: IMessage[];
    showImportArchiveView?: boolean;
    showMeetingSelection?: boolean;
    onSaveComplete?(response: IArchiveMeetingResult): void;
    onSaveError?(error: Error): void;
}

const MeetingArchivePanel: FC<IMeetingArchivePanelProps> = (props) => {
    const { isOpen, selectedMeetings, showImportArchiveView, showMeetingSelection, dataProvider, onDismiss, onSaveComplete } = props;
    const { messageState, dispatch } = useMessages();
    const { p360State } = useP360();
    const [documentRegUrl, setDocumentRegUrl] = useState<string>("");
    const messageCaseViewRef = useRef<IMessageCaseViewRef>(null);
    const messageNewDocumentViewRef = useRef<IMessageNewDocumentViewRef>(null);
    const meetingImportArchiveViewRef = useRef<IMeetingImportArchiveViewRef>(null);
    const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
    const [showDocumentCreatedMessage, setShowDocumentCreatedMessage] = useState<boolean>(false);

    const closePanel = useCallback(() => {
        setIsSavingInProgress(false);
        setShowDocumentCreatedMessage(false);
        setDocumentRegUrl("");
        dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: 0 } });
        dispatch({ type: MessagesActionType.UpdateSearchCriteria, payload: { searchCriteria: EnumSearchCriteria.Case } });
        dispatch({
            type: MessagesActionType.ArchiveMessages, payload: {
                caseDocumentData: {
                    ...messageState.caseDocumentData,
                    caseNumber: "",
                    documentNumber: "",
                    documentTitle: messageState.meetingState.meeting.topic,
                    selectionNewOrExisting: EnumCase.ExistingCase
                }
            }
        });
        dispatch({
            type: MessagesActionType.ImportArchive, payload: {
                importArchive: {
                    ...messageState.importArchive,
                    registrationMethod: ImportArchiveOption.ArchiveAsSingleDocument,
                    documentTitle: messageState.meetingState.meeting.topic
                }
            }
        });
        onDismiss && onDismiss();
    }, [dispatch, messageState.caseDocumentData, messageState.importArchive, messageState.meetingState.meeting.topic, onDismiss]);

    const onSavingComplete = useCallback((response: IArchiveMeetingResult) => {
        setIsSavingInProgress(false);
        if (messageState.importArchive.registrationMethod === ImportArchiveOption.ArchiveAsSingleDocument && response.Recno) {
            const registerUrl = getDocumentRegistrationViewUrl(p360State.p360Url, response.Recno, Constants.RegistrationViewsType.BIF);
            setDocumentRegUrl(registerUrl);
            setShowDocumentCreatedMessage(true);
            openDocumentRegistrationPage(registerUrl);
        }
        if (onSaveComplete)
            onSaveComplete(response);
    }, [messageState.importArchive.registrationMethod, onSaveComplete, p360State.p360Url]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <RenderPanelHeader closePanel={closePanel} />
        ), [closePanel]);

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <RenderPanelFooter {...props} dataProvider={dataProvider} closePanel={closePanel}
                showMeetingSelection={showMeetingSelection}
                selectedMessages={selectedMeetings}
                messageCaseViewRef={messageCaseViewRef}
                messageNewDocumentViewRef={messageNewDocumentViewRef}
                meetingImportArchiveViewRef={meetingImportArchiveViewRef}
                onSaveComplete={onSavingComplete}
                archivingInProgress={setIsSavingInProgress}
                isMeetingImportArchiveViewVisible={showImportArchiveView} />
        ), [closePanel, dataProvider, onSavingComplete, selectedMeetings, showImportArchiveView, showMeetingSelection]
    );

    const getStepWizardContent = () => {
        const steps = [];

        if (showMeetingSelection) {
            steps.push(<MeetingSelectionView key={"MeetingSelectionView"} {...props} />);
        }

        if (showImportArchiveView) {
            steps.push(<MeetingImportArchiveView key={"MeetingImportArchiveView"} {...props} documentRegUrl={documentRegUrl} documentCreationSuccess={showDocumentCreatedMessage} ref={meetingImportArchiveViewRef} isSaving={isSavingInProgress} />);
        } else {
            steps.push(<MessageCaseView key={"MessageCaseView"} {...props} ref={messageCaseViewRef} isFileArchiveAction={isOpen} />);
            steps.push(<MessageNewDocumentView key={"MessageNewDocumentView"} {...props} ref={messageNewDocumentViewRef} selectedMessages={selectedMeetings} />);
        }

        return <StepWizard switchToSteps={[messageState.stepWizard]}>{steps}</StepWizard>;
    };

    return <Panel type={PanelType.custom} customWidth="418px" isOpen={isOpen} onDismiss={closePanel} closeButtonAriaLabel={t('Close')}
        styles={{
            content: { marginTop: '-10px' }, footer: { backgroundColor: 'white', borderTop: '1px solid #e9e9e9' }
        }} isFooterAtBottom={true} onRenderNavigation={onRenderNavigationContent}
        onRenderFooterContent={onRenderFooterContent} isHiddenOnDismiss={false}>
        {
            isSavingInProgress && <div style={{ marginTop: '10px' }}><ProgressIndicator /></div>
        }
        {getStepWizardContent()}
    </Panel>;
};

export default MeetingArchivePanel;