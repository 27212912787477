export class Constants {
    static readonly Capabilities = {
        Get360DocumentsWithFiles: "Get360DocumentsWithFiles",
        ImportFiles: "ImportFiles",
        DocumentsSearch: "DocumentsSearch",
        GetDocumentsByDocumentNumber: "GetDocumentsByDocumentNumber",
        GetHistory: "GetHistory",
        AppPermissionsEndpoints: "AppPermissionsEndpoints",
        ArchiveOnExistingDocument: "ArchiveOnExistingDocument",
        TruncateLongFileNames: "TruncateLongFileNames",
        DenyRestricted: "DenyRestricted",
        DisallowCaseCreation: "DisallowCaseCreation",
        ChatArchiving: "ChatArchiving",
        AutomaticArchiving: "AutomaticArchiving",
        ArchiveThroughImportArchive: "ArchiveThroughImportArchive"
    };

    static readonly HomeDir = 'Home';
    static readonly CaseTitleMaxLength = 254;
    static readonly DocumentTitleMaxLength = 254;
    static readonly FileNameMaxLength = 254;
    static readonly FileNameMaxLengthWithoutBLTruncate = 200;
    static readonly MeetingRecordingNotAvailable = "NotAvailable";
    static readonly DocumentImportArchiveRecno = "7";
    static readonly EventDetail = {
        CallRecordingEventMessageDetail: '#microsoft.graph.callRecordingEventMessageDetail',
        CallEndedEventMessageDetail: '#microsoft.graph.callEndedEventMessageDetail',
        CallRecordingStatusSuccess: 'success'
    };

    static readonly ConfigKeys = {
        P360Url: 'p360url',
        DefaultP360Url: 'default360Url',
        UseInteractiveAuthentication: 'iauth',
        ShowInteractiveAuthentication: 'showIAuth',
        DefaultUseInteractiveAuthentication: 'defaultIAuth',
        ForceInteractiveAuthentication: 'forceIAuth'
    };

    static readonly ArchivingMethod = {
        TeamsArchivingOnly: 'TeamsArchivingOnly',
        ForceArchivingByImportArchive: 'ForceArchivingByImportArchive',
        AllowArchivingByImportArchive: 'AllowArchivingByImportArchive'
    };

    static readonly RegistrationViewsType = {
        BIF: 'BIF',
        PnB: 'PNB',
        AI: 'AI'
    };

    static readonly RegistrationViewsUrl = {
        BIF: {
            RelativeUrl: "/locator.aspx?name=DMS.Dialog.ConnectedDocumentDialog&SAVEAS=1&MoveTo=1&UnregisteredDoc=true&dialog=modal&_navigationtarget=self&recno="
        },
        PnB: {
            RelativeUrl: "/locator/DMS/Case/RegisteringView?selectedDocReno=" // yes, with a typo
        },
        AI: {
            RelativeUrl: "/locator/DMS/AI/RegisterDocument?recno="
        }
    };
}