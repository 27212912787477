import React from 'react';
import { Link, Stack } from '@fluentui/react';
import PartiallyArchivedIcon from '../../images/partially-archived.svg';
import { t } from 'i18next';
import { CombineUrl, getValueFromQueryString } from '../../helpers/UrlHelper';
import { iconClass } from '../../styles/MergeStyleSets';
import { getDocumentRegistrationViewUrl } from '../../helpers/DocumentRegistrationHelper';
import { Constants } from '../../helpers/Constants';

interface NotRegisteredDocumentLinkProps {
    documentRelativeUrl: string;
    p360url: string;
}

const NotRegisteredDocumentLink: React.FC<NotRegisteredDocumentLinkProps> = ({ documentRelativeUrl, p360url }) => {
    const documentRecno = getValueFromQueryString(documentRelativeUrl, 'recno');
    const registerUrl = getDocumentRegistrationViewUrl(p360url, parseInt(documentRecno as string), Constants.RegistrationViewsType.BIF);

    return (
        <Stack horizontal horizontalAlign="start">
            <span style={{ display: 'flex', padding: '4px 0' }}>
                <img src={PartiallyArchivedIcon} className={iconClass} alt={t('Document')} />
            </span>
            <span>
                <Link target="_blank" href={CombineUrl(p360url, registerUrl)}>{t('Register document')}</Link>
            </span>
        </Stack>
    );
};

export default NotRegisteredDocumentLink;