import React, { useState, useMemo, useCallback } from 'react';
import { AxiosError } from 'axios';

export type ErrorType = AxiosError | undefined;

export interface ApiErrorContextType {
    error: ErrorType,
    addError: (error: ErrorType) => void,
    removeError: () => void
}

export const ApiErrorContext = React.createContext<ApiErrorContextType>({
    error: undefined,
    addError: () => undefined,
    removeError: () => undefined
});

export default function ApiErrorHandler({ children }: Readonly<{ children: React.ReactElement }>): JSX.Element {
    const [error, setError] = useState<ErrorType>();

    const removeError = () => setError(undefined);

    const addError = useCallback((incomingError: ErrorType) => {
        if (error?.message !== incomingError?.message || error?.stack !== incomingError?.stack) {
            setError(incomingError);
        }
    }, [error]);

    const contextValue = useMemo(() => ({
        error,
        addError,
        removeError
    }), [addError, error]);

    return (
        <ApiErrorContext.Provider value={contextValue}>
            {children}
        </ApiErrorContext.Provider>
    );
}

export const useApiErrorHandler: () => ApiErrorContextType = () => React.useContext(ApiErrorContext);