import { v4 as uuidv4 } from "uuid";
import React, { createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Constants } from "../helpers/Constants";
import { MapTeamsContextToITeamRequest } from "../helpers/Mapper";
import { ChannelAutomaticArchivingInfo, GetChannelAutomaticArchivingInfoRequestParameter, IFileListItem, ISharepointFileResponse, ITeamRequest, ITeamServiceRequest } from "../model/model";
import { DataProvider } from "../providers/DataProvider";
import FilesReducer, { FilesActionType, IFilesState } from "./FilesReducer";
import { useTeams } from "./TeamsContext";
import { t } from "i18next";
import { SessionStorageProvider, useSessionStorageProvider } from "../providers/SessionStorageProvider";
import { Features } from "../config/Features";
import { useP360 } from "./P360Context";

interface LoadingInfo {
    isLoading: boolean;
    currentFolderIsLoading: boolean;
    loadingStates: LoadingState[];
}

interface LoadingState {
    id: string;
    loading: boolean;
    folderId?: string;
    message?: string;
}

export interface FilesContextType {
    currentFolder?: IFileListItem;
    channelRootFolder?: IFileListItem;
    loadingInfo: LoadingInfo;
    allFiles?: IFileListItem[];
    selectedFiles?: IFileListItem[];
    currentFiles?: IFileListItem[];
    navigateToFolder(folderId: string): void;
    addImportedFiles(folder: IFileListItem, files: ISharepointFileResponse[]): void;
    reloadTeamsFiles(folderNow: IFileListItem): void;
    updateFilesArchiveStatus(folder: IFileListItem, files: IFileListItem[]): void;
    removeSyncConflict(file: IFileListItem): void;
    setChannelAutomaticArchivingInfo(channelInfo: ChannelAutomaticArchivingInfo | ISharepointFileResponse): void;
    refreshArchivingStatusForFiles(files: IFileListItem[], folder?: IFileListItem): Promise<void>;
}

interface FilesContextProviderProps {
    children: React.ReactElement;
    dataProvider: DataProvider;
}

const FilesContext = createContext<FilesContextType | undefined>(undefined);

function FilesContextProvider(props: Readonly<FilesContextProviderProps>): JSX.Element {
    const { children, dataProvider } = props;
    const { teamsState } = useTeams();
    const { p360State } = useP360();

    const sessionStorageProvider = useSessionStorageProvider();

    const rootFolder: IFileListItem = useMemo(() => {
        return { fileId: Constants.HomeDir, fileDriveId: Constants.HomeDir, fileName: Constants.HomeDir };
    }, []);

    const getInitialFilesState = useCallback((): IFilesState => {
        const sessionStorageState = sessionStorageProvider.getItem<IFilesState>(SessionStorageProvider.FilesStateKey);
        return {
            files: sessionStorageState?.files ?? [rootFolder]
        } as IFilesState;
    }, [rootFolder, sessionStorageProvider]);

    const [state, dispatch] = useReducer(FilesReducer, null, getInitialFilesState);

    const initialFolder = Features.UseNewLoading ? sessionStorageProvider.getItem<IFileListItem>(SessionStorageProvider.CurrentFolderKey) ?? rootFolder : rootFolder;
    const [currentFolder, setCurrentFolder] = useState<IFileListItem>(initialFolder);

    const [loadingState, setLoadingState] = useState<LoadingState[]>([]);
    const addLoadingState = useCallback((loadingState: LoadingState) => { setLoadingState((prevLoadingState) => [...prevLoadingState, loadingState]); }, []);
    const removeLoadingState = useCallback((loadingID: string) => { setLoadingState((prevLoadingState) => [...prevLoadingState.filter(l => l.id !== loadingID)]); }, []);
    const getLoadingInfo = useCallback(() => {
        return {
            isLoading: loadingState.some(l => l.loading),
            currentFolderIsLoading: loadingState.some(l => l.loading && l.folderId === currentFolder.fileId),
            loadingStates: loadingState
        };
    }, [currentFolder.fileId, loadingState]);

    const getCurrentFiles = useCallback(() => { return state.files?.filter(f => f.parentId === currentFolder?.fileId); }, [currentFolder?.fileId, state.files]);
    const getChannelRootFolder = useCallback(() => { return state.files?.find(f => f.fileId === Constants.HomeDir); }, [state.files]);

    const navigateToFolder = useCallback((folderId: string) => {
        if (folderId && folderId !== currentFolder?.fileId) {
            const newFolder = state.files?.find(file => file.fileId === folderId) as IFileListItem;
            setCurrentFolder(newFolder);
            if (Features.UseNewLoading) {
                sessionStorageProvider.setItem(SessionStorageProvider.CurrentFolderKey, newFolder);
            }
        }
    }, [currentFolder?.fileId, sessionStorageProvider, state.files]);

    const refreshTeamsFiles = (folder: IFileListItem, files: ISharepointFileResponse[]): void => {
        dispatch({ type: FilesActionType.RefreshFilesFromTeams, payload: { folder: folder, data: files } });
    };

    const addImportedFiles = (folder: IFileListItem, files: ISharepointFileResponse[]): void => {
        dispatch({ type: FilesActionType.AddImportedFiles, payload: { folder: folder, data: files } });
    };

    const updateFilesArchiveStatus = (folder: IFileListItem, files: ISharepointFileResponse[]): void => {
        dispatch({ type: FilesActionType.RefreshArchivingStatus, payload: { folder: folder, data: files } });
    };

    const setArchivingStatusChecked = useCallback((items: ISharepointFileResponse[]): void => {
        dispatch({ type: FilesActionType.SetArchivingStatusChecked, payload: { folder: currentFolder, data: items } });
    }, [currentFolder]);

    const getArchivingStatus = useCallback(async (folder: IFileListItem, request: ITeamServiceRequest): Promise<void> => {
        const archiveLoadingId = uuidv4();
        addLoadingState({ id: archiveLoadingId, loading: true, folderId: folder.fileId, message: t("Sync archived files from 360...") });

        return dataProvider?.P360?.getFilesArchivedStatus(request).then((res) => {
            if (res.data?.length > 0) {
                updateFilesArchiveStatus(folder, res.data);
            }
        }).finally(() => {
            removeLoadingState(archiveLoadingId);
        });
    }, [dataProvider?.P360, addLoadingState, removeLoadingState]);

    const mapRequest = useCallback((
        request: ITeamServiceRequest,
        items: ISharepointFileResponse[]
    ): ITeamServiceRequest => {
        const result = { ...request };
        result.sharepointFileRequest = {
            driveItems: items.map(f => {
                return {
                    driveId: f.DriveId,
                    itemId: f.Id,
                    isFolder: f.Folder != null
                };
            })
        };
        return result;
    }, []);

    const currentFilesRef = useRef<IFileListItem[]>(getCurrentFiles());
    useEffect(() => {
        currentFilesRef.current = getCurrentFiles();
    }, [getCurrentFiles]);

    const handleArchivingStatusCheck = useCallback((request: ITeamServiceRequest, folder: IFileListItem, items: ISharepointFileResponse[]): void => {
        const alreadyArchivedFilesFromState = currentFilesRef?.current?.filter(f => f.isConnectedTo360 === true && !f.isFolder);

        const promises: Promise<void>[] = [];

        const notArchivedFiles = items.filter(f => f.Folder === null && !alreadyArchivedFilesFromState?.some(aaf => aaf.fileId === f.Id));
        if (notArchivedFiles.length > 0) {
            const notArchivedRequest = mapRequest(request, notArchivedFiles);
            promises.push(getArchivingStatus(folder, notArchivedRequest));
        }

        const archivedFiles = items.filter(f => f.Folder === null && !notArchivedFiles?.some(naf => naf.Id === f.Id));
        if (archivedFiles.length > 0) {
            const archivedRequest = mapRequest(request, archivedFiles);
            promises.push(getArchivingStatus(folder, archivedRequest));
        }

        const folders = items.filter(f => f.Folder != null);
        if (folders.length > 0) {
            const folderRequest = mapRequest(request, folders);
            promises.push(getArchivingStatus(folder, folderRequest));
        }

        Promise.allSettled(promises).then(() => {
            if (Features.UseNewLoading) {
                setArchivingStatusChecked(items);
            }
        });
    }, [getArchivingStatus, mapRequest, setArchivingStatusChecked]);

    const refreshArchivingStatusForFiles = useCallback(async (files: IFileListItem[], folder?: IFileListItem): Promise<void> => {
        const loadingId = uuidv4();
        addLoadingState({ id: loadingId, loading: true, message: t("Sync archived files from 360...") });

        try {
            const token = await teamsState.getAccessToken();
            const request: ITeamServiceRequest = {
                accessToken: token,
                teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext),
                sharepointFileRequest: {
                    driveItems: files.map(f => {
                        return {
                            driveId: f.fileDriveId,
                            itemId: f.fileId,
                            isFolder: f.isFolder
                        };
                    })
                }
            };
            await getArchivingStatus(folder ?? currentFolder, request);
        } finally {
            removeLoadingState(loadingId);
        }
    }, [addLoadingState, currentFolder, getArchivingStatus, removeLoadingState, teamsState]);


    const removeSyncConflict = useCallback((file: IFileListItem): void => {
        dispatch({ type: FilesActionType.RemoveSyncConflict, payload: { folder: currentFolder, data: [file] } });
    }, [currentFolder]);

    const setChannelAutomaticArchivingInfo = useCallback((channelInfo: ChannelAutomaticArchivingInfo | ISharepointFileResponse): void => {
        dispatch({ type: FilesActionType.SetChannelAutomaticArchivingInfo, payload: { folder: rootFolder, channelInfo: channelInfo } });
    }, [rootFolder]);

    const updateChannelAutomaticArchivingInfo = useCallback(async (token: string) => {
        const request: GetChannelAutomaticArchivingInfoRequestParameter = {
            accessToken: token,
            teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest
        };

        dataProvider.P360.getChannelAutomaticArchivingInfo(request).then((res) => {
            setChannelAutomaticArchivingInfo(res.data);
        });
    }, [teamsState.userContext, dataProvider.P360, setChannelAutomaticArchivingInfo]);

    const reloadTeamsFiles = useCallback(async (folderNow: IFileListItem): Promise<void> => {
        const loadingId = uuidv4();
        addLoadingState({ id: loadingId, loading: true, folderId: folderNow?.fileId, message: t("Fetching files...") });

        teamsState.getAccessToken().then((token) => {
            const request: ITeamServiceRequest = {
                accessToken: token,
                teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext),
                fileId: folderNow?.fileId,
                driveId: folderNow?.fileDriveId
            };

            // refresh channel automatic synchronization info only when navigating to root folder
            if (folderNow?.fileId === rootFolder.fileId && p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.AutomaticArchiving) === true) {
                updateChannelAutomaticArchivingInfo(token);
            }

            const getFilesMethod = (folderNow?.fileId === rootFolder.fileId) ? dataProvider.P360.getChannelFiles(request) : dataProvider.P360.getFileItems(request);
            getFilesMethod.then(filesResult => {
                refreshTeamsFiles(folderNow, filesResult.data);

                if (filesResult.data?.length <= 0) {
                    return;
                }

                handleArchivingStatusCheck(request, folderNow, filesResult.data);
            }).finally(() => { removeLoadingState(loadingId); });
        });
    }, [addLoadingState, dataProvider.P360, handleArchivingStatusCheck, p360State.backendCapabilities?.Capabilities, removeLoadingState, rootFolder.fileId, teamsState, updateChannelAutomaticArchivingInfo]);

    // store state to session storage whenever it changes
    useEffect(() => {
        if (state.files?.length > 1) {
            sessionStorageProvider.setItem(SessionStorageProvider.FilesStateKey, state);
        }
    }, [sessionStorageProvider, state]);

    const loadingStateRef = useRef<LoadingState[]>(loadingState);
    useEffect(() => {
        loadingStateRef.current = loadingState;
    }, [loadingState]);

    useEffect(() => {
        const folderNow = { ...currentFolder };

        // don't start new loading if previous is still running
        if (loadingStateRef.current.some((l: LoadingState) => l.loading && l.folderId === folderNow.fileId)) {
            return;
        }

        reloadTeamsFiles(folderNow);
    }, [currentFolder, reloadTeamsFiles]);

    const filesContext: FilesContextType = useMemo(() => ({
        currentFolder: currentFolder,
        channelRootFolder: getChannelRootFolder(),
        loadingInfo: getLoadingInfo(),
        allFiles: state.files,
        currentFiles: getCurrentFiles(),
        navigateToFolder: navigateToFolder,
        addImportedFiles: addImportedFiles,
        reloadTeamsFiles: reloadTeamsFiles,
        updateFilesArchiveStatus: updateFilesArchiveStatus,
        removeSyncConflict: removeSyncConflict,
        setChannelAutomaticArchivingInfo: setChannelAutomaticArchivingInfo,
        refreshArchivingStatusForFiles: refreshArchivingStatusForFiles
    }), [currentFolder, getChannelRootFolder, getCurrentFiles, getLoadingInfo, navigateToFolder, refreshArchivingStatusForFiles, reloadTeamsFiles, removeSyncConflict, setChannelAutomaticArchivingInfo, state.files]);

    return (
        <FilesContext.Provider value={filesContext}>
            {children}
        </FilesContext.Provider>
    );
}

function useFiles() {
    const context = React.useContext(FilesContext);
    if (context === undefined) {
        throw new Error('useFiles must be used within a FilesProvider');
    }
    return context;
}

export { FilesContext, FilesContextProvider, useFiles };