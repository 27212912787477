import { FC } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { IMessage } from "../../model/model";
import { ActionButton } from "@fluentui/react";
import DocumentEntityIcon from '../../images/documententity.svg';
import { t } from "i18next";

interface IMeetingRecordingProps {
    message: IMessage;
}

const MeetingRecording: FC<IMeetingRecordingProps> = (props) => {
    return <div className={classNames.meetingItemContainer} style={{ height: '100px' }}>
        <div className={classNames.meetingItemContainerHeader}>{t('Recording')}</div>
        <ActionButton href={props.message.eventDetail?.callRecordingUrl} target="_blank" style={{ marginTop: '10px', marginLeft: '10px' }}
            iconProps={{ imageProps: { src: DocumentEntityIcon, width: 20, height: 25 } }}>
            <span style={{ textAlign: 'left' }}>{props.message.eventDetail?.callRecordingDisplayName}</span>
        </ActionButton>
    </div>;
};

export default MeetingRecording;