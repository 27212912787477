import { FC, useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { ICommonProps } from "../ICommonProps";
import { CheckboxVisibility, IColumn, ISelection, Selection } from "@fluentui/react";
import { useTeams } from "../../context/TeamsContext";
import { useMessages } from "../../context/MessagesContext";
import { FormatDateTime } from "../../helpers/FormatDateTime";
import ReadMore from "../chatArchiving/ReadMore";
import ProfilePhoto from "../chatArchiving/ProfilePhoto";
import { IMessage, IMessageItem } from "../../model/model";
import { MeetingShimmeredDetailsList } from "./MeetingRecordingList";

interface IMeetingChatProps extends ICommonProps {
    selectedMeetings?: IMessage[];
    setSelectedMeetings?: (data: IMessage[]) => void;
    // use only for testing purposes
    skipViewportMeasures?: boolean;
}

const MeetingChat: FC<IMeetingChatProps> = (props) => {
    const { selectedMeetings, setSelectedMeetings, skipViewportMeasures } = props;
    const { teamsState } = useTeams();
    const { messageState } = useMessages();
    const [messages, setMessages] = useState<IMessage[]>([]);

    const onRenderCreatedDateTime = useCallback((item: IMessage) => {
        return <span>{FormatDateTime.convert(item.createdDateTime as Date)}</span>;
    }, []);

    const onRenderLastModifiedDateTime = useCallback((item: IMessage) => {
        return <span>{FormatDateTime.convert(item.LastModifiedDateTime as Date)}</span>;
    }, []);

    const onRenderMessage = useCallback((item: IMessage) => {
        return <ReadMore content={item.Body?.Content} />;
    }, []);

    const onRenderFromUser = useCallback((item: IMessage) => {
        const messageItem: IMessageItem = { fromUser: item.From?.User.DisplayName };
        return <ProfilePhoto showPhotoShimmer={false} showProfilePhoto={false} item={messageItem} />;
    }, []);

    const columns: IColumn[] = [
        { key: 'createdDateTime', name: t('Created Date'), minWidth: 80, maxWidth: 180, isResizable: true, onRender: onRenderCreatedDateTime },
        { key: 'lastModified', name: t('Modified Date'), minWidth: 80, maxWidth: 180, isResizable: true, onRender: onRenderLastModifiedDateTime },
        { key: 'fromUser', name: t('Name'), minWidth: 120, maxWidth: 180, onRender: onRenderFromUser },
        { key: 'message', name: t('Message'), minWidth: 200, isResizable: true, onRender: onRenderMessage }
    ];

    const [selection] = useState<ISelection<IMessage>>(() => new Selection<IMessage>({
        onSelectionChanged: () => {
            if (setSelectedMeetings)
                setSelectedMeetings(selection.getSelection());
        },
        items: selectedMeetings ?? [],
        getKey: (item: IMessage) => item.Id as string
    }));

    const getChatDetails = useCallback(() => {
        if (messageState.meetingState.isLoading) return;

        const messages = [...messageState.meetingState.messages];
        const chatMessages = messages.filter((message) => message.MessageType === 'message').sort((a, b) => new Date(a.createdDateTime as Date).getTime() - new Date(b.createdDateTime as Date).getTime());
        setMessages(chatMessages);
    }, [messageState.meetingState.isLoading, messageState.meetingState.messages]);

    useEffect(() => {
        if (selectedMeetings?.length === 0) {
            selection.setAllSelected(false);
        }
        getChatDetails();
    }, [getChatDetails, selectedMeetings?.length, selection]);

    return MeetingShimmeredDetailsList({
        uniqueKey: teamsState.userContext?.chat?.id,
        messages: messages,
        columns: columns,
        selection: selection,
        checkboxVisibility: messageState.meetingState.isLoading ? CheckboxVisibility.hidden : CheckboxVisibility.always,
        enableShimmer: messageState.meetingState.isLoading,
        skipViewportMeasures: skipViewportMeasures
    });
};

export default MeetingChat;