import { app } from "@microsoft/teams-js";
import { FakeMsTeamsProvider } from "./FakeMsTeamsProvider";
import { FakeP360Provider } from "./FakeP360Provider";
import { IMsTeamsProvider } from "./IMsTeamsProvider";
import { IP360Provider } from "./IP360Provider";
import { MsTeamsProvider } from "./MsTeamsProvider";
import { P360Provider } from "./P360Provider";
import { CombineUrl, TrimTrailingSlash } from "../helpers/UrlHelper";
import { Constants } from "../helpers/Constants";

export enum AuthStatus {
    Loading = "loading",
    Authorised = "authorised",
    NotAuthorised = "notAuthorised"
}

export interface IAuthResult {
    AuthStatus: AuthStatus,
    AccessToken: string,
    Message: string
}

/** Configuration that is combined of 
 * - instance-specific configuration (stored in the Teams `contentUrl`, managed through config screen) 
 * - app-level configuration (stored in app manifest, managed through Teams developer portal) */
export interface IAppConfig {
    /** The 360 URL configured for this instance of the app */
    p360Url: string;
    /** The default 360 URL configured for this app in app manifest */
    default360Url: string;
    /** Interactive authentication configuration for this instance of the app */
    useInteractiveAuthentication: boolean;
    /** Defines if interactive authentication configuration is available, defined in app manifest */
    showInteractiveAuthentication: boolean;
    /** Default interactive authentication configuration for this app in app manifest */
    defaultUseInteractiveAuthentication: boolean;
    /** Force interactive authentication configuration for this app in app manifest */
    forceInteractiveAuthentication: boolean;
}

export class DataProvider {
    public MsTeams: IMsTeamsProvider;
    public P360: IP360Provider;

    constructor(msTeamsProvider?: IMsTeamsProvider, p360Provider?: IP360Provider) {
        const useFakes = process.env.REACT_APP_USE_FAKES === 'true';

        this.MsTeams = msTeamsProvider ?? useFakes ? new FakeMsTeamsProvider() : new MsTeamsProvider();
        this.P360 = p360Provider ?? useFakes ? new FakeP360Provider() : new P360Provider();

        // Loading fakes should be done only when they are used, but could not get this working. There are other methods like lazy loading, we should look into this later. 
        // try: React.lazy(() => import('./FakeMsTeamsProvider')), maybe React.suspense is needed?	
        // if (useFakes) {
        //     import("./FakeMsTeamsProvider").then(({ FakeMsTeamsProvider }) => { this.MsTeams = new FakeMsTeamsProvider(); });
        //     import("./FakeP360Provider").then(({ FakeP360Provider }) => { this.P360 = new FakeP360Provider(); });
        // }
    }

    public getConfiguration(): Promise<IAppConfig> {
        return new Promise<IAppConfig>((resolve) => {
            this.MsTeams.getSettings().then(settings => {
                const configuredValues = new URLSearchParams(settings.contentUrl != null ? settings.contentUrl.split('?')[1] : "");
                const defaultValues = new URLSearchParams(window.document.location.search);

                const result: IAppConfig = {
                    p360Url: TrimTrailingSlash(configuredValues.get(Constants.ConfigKeys.P360Url) as string),
                    default360Url: TrimTrailingSlash(defaultValues.get(Constants.ConfigKeys.DefaultP360Url) as string),
                    useInteractiveAuthentication: configuredValues.get(Constants.ConfigKeys.UseInteractiveAuthentication) === "true",
                    showInteractiveAuthentication: defaultValues.get(Constants.ConfigKeys.ShowInteractiveAuthentication) === "true",
                    defaultUseInteractiveAuthentication: defaultValues.get(Constants.ConfigKeys.DefaultUseInteractiveAuthentication) === "true",
                    forceInteractiveAuthentication: defaultValues.get(Constants.ConfigKeys.ForceInteractiveAuthentication) === "true"
                };

                resolve(result);
            });
        });
    }

    public authenticate(): Promise<IAuthResult> {
        return new Promise<IAuthResult>((resolve) => {
            this.MsTeams.getAuthToken().then(token => {
                resolve({ AuthStatus: AuthStatus.Authorised, AccessToken: token, Message: "" });
            }).catch(reason => {
                this.MsTeams.notifyFailure({ reason: app.FailedReason.AuthFailed, message: AuthStatus.NotAuthorised });
                resolve({ AuthStatus: AuthStatus.NotAuthorised, AccessToken: "", Message: reason });
            });
        });
    }

    public async authenticateTo360(p360url: string, forceRefresh?: boolean): Promise<string> {
        p360url = TrimTrailingSlash(p360url);
        const sessionKey = `${p360url}_p360Session`;
        const sessionExpiryKey = `${p360url}_p360SessionExpiry`;
        const sessionExpiry = sessionStorage.getItem(sessionExpiryKey);
        const currentTime = new Date().getTime();

        if (forceRefresh) {
            sessionStorage.removeItem(sessionKey);
            sessionStorage.removeItem(sessionExpiryKey);
        }
        else if (sessionExpiry && currentTime < parseInt(sessionExpiry)) {
            return Promise.resolve(sessionStorage.getItem(sessionKey) as string);
        }

        const returnUrl = CombineUrl(window.location.href, "authorize");
        const p360RedirectUrl = CombineUrl(p360url, "redirect.aspx", `?url=${encodeURIComponent(returnUrl)}`);
        return this.MsTeams.authenticate({
            url: `${p360RedirectUrl}`,
            width: 600, // Review with different IdP's.
            height: 800, // Review with different IdP's.
            isExternal: false // This might need to be configurable, depending on IdP. Test with Feide and FK.
        }).then(authResult => {
            sessionStorage.setItem(sessionKey, authResult);
            sessionStorage.setItem(sessionExpiryKey, (currentTime + 3600000).toString()); // 1 hour in milliseconds = 3600000

            return authResult;
        }).catch(reason => {
            try {
                this.MsTeams.notifyAuthenticationFailure(reason);
            } catch {
                // This fails if not in authentication context, can be ignored.
            }
            return Promise.reject(new Error("Unable to authorize user", { cause: reason }));
        });
    }
}