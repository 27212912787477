import { FC } from 'react';
import { iconClass } from '../../styles/MergeStyleSets';
import { Link, Shimmer, Stack } from '@fluentui/react';
import { IFileListItem } from '../../model/model';
import DocumentEntityIcon from '../../images/documententity.svg';
import { CombineUrl, getValueFromQueryString } from '../../helpers/UrlHelper';
import { t } from "i18next";
import { Features } from '../../config/Features';
import NotRegisteredDocumentLink from '../fileList/NotRegisteredDocumentLink';
import { Constants } from '../../helpers/Constants';

interface IDocumentConnectionLinkProps {
    item: IFileListItem,
    p360url: string,
    loading?: boolean
}

const DocumentConnectionLink: FC<IDocumentConnectionLinkProps> = (props) => {
    const { item, loading, p360url } = props;

    if (Features.UseNewLoading && !item.archivingStatusChecked && loading) {
        return <Shimmer aria-label={t('Loading...')} />;
    }

    if (item.documentConnection) {
        const documentArchiveRecno = getValueFromQueryString(item.documentConnection, 'subtype');
        if (documentArchiveRecno && documentArchiveRecno === Constants.DocumentImportArchiveRecno) {
            return <NotRegisteredDocumentLink documentRelativeUrl={item.documentConnection} p360url={p360url} />;
        }
        return <Stack horizontal horizontalAlign="start">
            <span style={{ display: 'flex', padding: '4px 0' }}>
                <img src={DocumentEntityIcon} className={iconClass} alt={t('Document')} />
            </span>
            <span>
                <Link target="_blank" href={CombineUrl(p360url, item.documentConnection)} underline={true}>{props.item.documentTitle}</Link>
            </span>
        </Stack>;
    }

    if (item.isConnectedTo360) {
        const accessDeniedLabel = t('Access Denied');
        return <span style={{ color: 'red' }}>{accessDeniedLabel}</span>;
    }

    return null;
};

export default DocumentConnectionLink;