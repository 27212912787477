import React, { FC } from 'react';

export interface IStepWizardProps {
    children: React.ReactElement[];
    switchToSteps: number[];
}

export const StepWizard: FC<IStepWizardProps> = (props) => {
    const { children, switchToSteps } = props;
    return (<div>
        {children
            .filter((_, index) => switchToSteps.includes(index))
            .map((child, index) => React.cloneElement(child, { key: child.key }))}
    </div>);
};