import { useEffect } from "react";
import { DataProvider } from "../../providers/DataProvider";


interface IAuthorizeProps {
    dataProvider: DataProvider;
}

/// <summary>
/// Component that finalizes the authentication process.
/// This is used when Interactive authentication is used.
/// When the user is redirected back to the app after authentication in 360°, this component sets the final authentication status.
/// </summary>
const Authorize = (props: IAuthorizeProps) => {
    useEffect(() => {
        // Initialize the Teams SDK
        props.dataProvider.MsTeams.initialize().then(() => {
            props.dataProvider.MsTeams.notifyAuthenticationSuccess("authenticated");
        }).catch(() => {
            props.dataProvider.MsTeams.notifyAuthenticationFailure("Teams SDK initialization failed");
        });
    }, [props.dataProvider.MsTeams]);

    return (
        <div style={{ display: "none" }}>
            <h2>Finalizing Authentication...</h2>
            <p>This page should close automatically.</p>
        </div>
    );
};

export default Authorize;
