import React from "react";
import { Link } from "@fluentui/react";
import AllArchivedIcon from "../../images/all-archived.svg";
import { t } from "i18next";
import { openDocumentRegistrationPage } from "../../helpers/DocumentRegistrationHelper";

interface DocumentCreatedToImportArchiveMessageProps {
    documentRegUrl: string;
}

const DocumentCreatedToImportArchiveMessage: React.FC<DocumentCreatedToImportArchiveMessageProps> = ({ documentRegUrl }) => {
    if (!documentRegUrl) {
        return null;
    }

    return (
        <div style={{ marginTop: "50px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img
                    src={AllArchivedIcon}
                    alt={t("Document created")}
                    style={{ height: "18px", width: "18px" }}
                />
                <h3 style={{ marginLeft: '5px' }}>{t('Document created')}</h3>
            </div>
            <p>
                {t("Complete the registration m1")}{" "}
                <Link
                    style={{ textDecoration: "underline" }}
                    onClick={() => openDocumentRegistrationPage(documentRegUrl)}
                >
                    {t("Register document")}
                </Link>
            </p>
            <p>{t("Complete the registration m2")}</p>
            <p>{t("Complete the registration m3")}</p>
        </div>
    );
};

export default DocumentCreatedToImportArchiveMessage;