/** Join URL paths to create full URL. NOTE: only pass host in baseUrl, all path components should be passed in paths. */
export function CombineUrl(baseUrl: string, ...paths: string[]): string {
    if (!baseUrl) { throw Error("baseUrl is required"); }

    if (paths?.length) {
        paths[0] = paths[0].replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//, '');
    }

    const path = paths?.map(p => p.startsWith("?") || p.startsWith("/") ? p : p && `/${p}`).join("");
    return new URL(path, baseUrl).toString();
}

export function TrimTrailingSlash(url: string): string {
    return url?.endsWith("/") ? url.slice(0, -1) : url;
}

export function getValueFromQueryString(url: string, queryParamName: string) {
    const searchParams = new URLSearchParams(url);
    if (searchParams.has(queryParamName)) {
        return searchParams.get(queryParamName);
    }
    return "";
}