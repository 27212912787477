import React, { useCallback, useState } from 'react';
import { IChatArchivePanelProps } from './ChatArchivePanel';
import { useTeams } from '../../../context/TeamsContext';
import { useMessages } from '../../../context/MessagesContext';
import { ChoiceGroup, DefaultButton, IChoiceGroupOption, IPanelProps, IRenderFunction, Panel, PanelType, PrimaryButton, ProgressIndicator, TextField } from '@fluentui/react';
import { t } from 'i18next';
import { RenderPanelHeader } from '../../common/RenderPanelHeader';
import { useBoolean } from '@fluentui/react-hooks';
import { Constants } from '../../../helpers/Constants';
import { ImportArchiveOption } from '../../rightPanel/ImportArchiveContainer';
import { ArchiveChatsThroughImportArchiveRequestParameter, ITeamRequest } from '../../../model/model';
import { MapTeamsContextToITeamRequest, mapMessageItemToMessageResponse } from '../../../helpers/Mapper';
import { getDocumentRegistrationViewUrl, openDocumentRegistrationPage } from '../../../helpers/DocumentRegistrationHelper';
import { useP360 } from '../../../context/P360Context';
import DocumentCreatedToImportArchiveMessage from '../../common/DocumentCreatedToImportArchiveMessage';

const ChatImportArchivePanel: React.FC<IChatArchivePanelProps> = (props) => {
    const { isOpen, selectedMessages, includeAttachments, onSaveComplete, dataProvider, onDismiss } = props;
    const { teamsState } = useTeams();
    const { messageState } = useMessages();
    const { p360State } = useP360();
    const [isSaving, setIsSaving] = useBoolean(false);
    const [documentTitle, setDocumentTitle] = useState<string>("");
    const [showDocumentTitleError, setShowDocumentTitleError] = useState<boolean>(false);
    const [selectedImportArchiveOption, setSelectedImportArchiveOption] = useState<ImportArchiveOption>(ImportArchiveOption.ArchiveAsSingleDocument);
    const [documentRegUrl, setDocumentRegUrl] = useState<string>("");
    const [showDocumentCreatedMessage, setShowDocumentCreatedMessage] = useBoolean(false);

    const restoreDefaultState = useCallback(() => {
        setDocumentTitle("");
        setShowDocumentTitleError(false);
        setSelectedImportArchiveOption(ImportArchiveOption.ArchiveAsSingleDocument);
        setDocumentRegUrl("");
        setShowDocumentCreatedMessage.setFalse();
        setIsSaving.setFalse();
    }, [setIsSaving, setShowDocumentCreatedMessage]);

    const onDocumentTitleChange = useCallback((_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setDocumentTitle(newValue as string);
        setShowDocumentTitleError(newValue?.trim() === "");
    }, []);

    const importArchiveOptions: IChoiceGroupOption[] = [
        { key: ImportArchiveOption.ArchiveAsSingleDocument.toString(), text: t('Start registration for this document') },
        { key: ImportArchiveOption.SendTo360.toString(), text: t('Archive directly') }
    ];

    const onChangeImportArchiveOptions = useCallback((_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSelectedImportArchiveOption(option?.key as ImportArchiveOption);
    }, []);

    const closePanel = useCallback(() => {
        restoreDefaultState();
        onDismiss?.();
    }, [onDismiss, restoreDefaultState]);

    const onSave = useCallback(async () => {
        if (!documentTitle || documentTitle.trim() === "") {
            setShowDocumentTitleError(true);
            return;
        }

        setIsSaving.setTrue();
        try {
            const token = await teamsState.getAccessToken();
            const request: ArchiveChatsThroughImportArchiveRequestParameter = {
                AccessToken: token,
                TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
                DocumentTitle: documentTitle,
                MessageResponse: mapMessageItemToMessageResponse(messageState, selectedMessages),
                IncludeAttachments: includeAttachments
            };
            const response = await dataProvider?.P360.archiveChatsThroughImportArchive(request);

            if (response?.data) {
                if (selectedImportArchiveOption === ImportArchiveOption.ArchiveAsSingleDocument && response.data.Recno) {
                    const registerUrl = getDocumentRegistrationViewUrl(p360State.p360Url, response.data.Recno, Constants.RegistrationViewsType.BIF);
                    setDocumentRegUrl(registerUrl);
                    openDocumentRegistrationPage(registerUrl);
                    setShowDocumentCreatedMessage.setTrue();
                    setIsSaving.setFalse();
                }

                onSaveComplete?.(response.data, t('Document created'));
            }
        } finally {
            if (selectedImportArchiveOption !== ImportArchiveOption.ArchiveAsSingleDocument) {
                closePanel();
            }
        }
    }, [closePanel, dataProvider?.P360, documentTitle, includeAttachments, messageState, onSaveComplete, p360State.p360Url, selectedImportArchiveOption, selectedMessages, setIsSaving, setShowDocumentCreatedMessage, teamsState]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (_props, _defaultRender) => (
            <RenderPanelHeader closePanel={closePanel} />
        ), [closePanel]);

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (_props, _defaultRender) => (
            showDocumentCreatedMessage ?
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <PrimaryButton text={t('Close')} onClick={closePanel} />
                </div> :
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', justifyContent: 'flex-end' }}>
                    <DefaultButton text={t('Cancel')} disabled={isSaving} onClick={closePanel} />
                    <PrimaryButton disabled={isSaving || showDocumentCreatedMessage} onClick={onSave}
                        text={t('Create document in 360')} />
                </div>
        ),
        [closePanel, isSaving, onSave, showDocumentCreatedMessage]
    );

    return (
        <Panel type={PanelType.custom}
            customWidth="418px"
            isOpen={isOpen}
            onDismiss={closePanel}
            closeButtonAriaLabel={t('Close')}
            isFooterAtBottom={true}
            onRenderNavigation={onRenderNavigationContent}
            onRenderFooterContent={onRenderFooterContent}
            isHiddenOnDismiss={false}>
            {isSaving && <div><ProgressIndicator /></div>}
            <TextField label={t('Document title')}
                value={documentTitle}
                maxLength={Constants.DocumentTitleMaxLength}
                required
                multiline
                resizable={false}
                disabled={isSaving || showDocumentCreatedMessage}
                onChange={onDocumentTitleChange}
                errorMessage={showDocumentTitleError ? t('Required') : undefined} />
            <ChoiceGroup
                selectedKey={selectedImportArchiveOption}
                options={importArchiveOptions}
                onChange={onChangeImportArchiveOptions}
                disabled={isSaving || showDocumentCreatedMessage}
                required={true} />
            {showDocumentCreatedMessage && !isSaving && <DocumentCreatedToImportArchiveMessage documentRegUrl={documentRegUrl} />}
        </Panel>
    );
};

export default ChatImportArchivePanel;