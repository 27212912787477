import { FC, useCallback, useEffect, useState } from "react";
import { Features } from "../../config/Features";
import { classNames } from "../../styles/MergeStyleSets";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { IPivotItemProps, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, ThemeProvider } from "@fluentui/react";
import MeetingChat from "./MeetingChat";
import MeetingRecordingList from "./MeetingRecordingList";
import { useBoolean } from "@fluentui/react-hooks";
import { EnumUIConfiguration, IArchiveMeetingResult, IMessage } from "../../model/model";
import MeetingArchivePanel from "./panel/MeetingArchivePanel";
import { useMessages } from "../../context/MessagesContext";
import { useApiErrorHandler } from "../../context/ApiErrorHandler";
import ErrorNotification from "../error/ErrorNotification";
import { useP360 } from "../../context/P360Context";
import { Constants } from "../../helpers/Constants";

const MeetingArchiving: FC<ICommonProps> = (props) => {
    const { messageState } = useMessages();
    const { p360State } = useP360();
    const { error } = useApiErrorHandler();
    const [isArchivePanelOpen, { setTrue: openArchivePanel, setFalse: closeArchivePanel }] = useBoolean(false);
    const [selectedMeetings, setSelectedMeetings] = useState<IMessage[]>([]);
    const [successMessage, setSuccessMessage] = useState<IArchiveMeetingResult | undefined>();
    const [successMessageForImportArchive, setSuccessMessageForImportArchive] = useState<string | undefined>("");
    const [selectedPivotTab, setSelectedPivotTab] = useState<{ props: IPivotItemProps } | undefined>({ props: { headerText: t('Archive Meeting') } });

    const onArchiveHandler = useCallback(() => {
        openArchivePanel();
    }, [openArchivePanel]);

    const isImportArchiveVisible = useCallback(() => {
        return p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.ArchiveThroughImportArchive) &&
            (p360State.backendCapabilities?.Configuration.ArchivingMethod === Constants.ArchivingMethod.ForceArchivingByImportArchive ||
                p360State.backendCapabilities?.Configuration.UIConfiguration === EnumUIConfiguration.ProcessView ||
                p360State.backendCapabilities?.Configuration.UIConfiguration === EnumUIConfiguration.Sahke2);
    }, [p360State.backendCapabilities?.Capabilities, p360State.backendCapabilities?.Configuration.ArchivingMethod, p360State.backendCapabilities?.Configuration.UIConfiguration]);

    const onSaveComplete = useCallback((response: IArchiveMeetingResult) => {
        setSelectedMeetings([]);
        if (response.DocumentNumber)
            setSuccessMessage(response);
        if (response.Recno > 0)
            setSuccessMessageForImportArchive(t('Meetings document created'));
    }, []);

    const onSuccessMessageDismiss = useCallback(() => {
        setSuccessMessage(undefined);
        setSuccessMessageForImportArchive(undefined);
    }, []);

    const isMeetingTabSelected = useCallback(() => {
        return selectedPivotTab?.props.headerText === t('Archive Meeting');
    }, [selectedPivotTab?.props.headerText]);

    useEffect(() => {
        if (error) {
            closeArchivePanel();
        }
        if (selectedMeetings.length > 0)
            setSuccessMessage(undefined);
    }, [closeArchivePanel, error, isImportArchiveVisible, selectedMeetings.length]);

    if (Features.EnableMeetingArchiving) {
        return <ThemeProvider className={classNames.wrapper}>
            <ErrorNotification />
            {
                messageState.meetingState.meeting.Error &&
                <MessageBar messageBarType={MessageBarType.error}>{messageState.meetingState.meeting.Error.Message || messageState.meetingState.meeting.Error.Code}</MessageBar>
            }
            {successMessage &&
                <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={onSuccessMessageDismiss} dismissButtonAriaLabel={t('Close')}>
                    {t('Meetings archived successfully on Document')} <a href={successMessage.DocumentUrl} target="blank">{successMessage.DocumentNumber}</a>
                </MessageBar>
            }
            {successMessageForImportArchive &&
                <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={onSuccessMessageDismiss} dismissButtonAriaLabel={t('Close')}>
                    {t('Meetings document created')}
                </MessageBar>
            }
            <Pivot onLinkClick={setSelectedPivotTab}>
                <PivotItem headerText={t('Archive Meeting')}>
                    <MeetingRecordingList {...props} setSelectedMeetings={setSelectedMeetings} selectedMeetings={selectedMeetings} />
                </PivotItem>
                <PivotItem headerText={t('Archive Chat')}>
                    <MeetingChat {...props} setSelectedMeetings={setSelectedMeetings} selectedMeetings={selectedMeetings} />
                </PivotItem>
            </Pivot>
            {
                selectedMeetings.length > 0 && <PrimaryButton className={classNames.meetingArchivingArchiveButtonStyle} text={t('Archive')} onClick={onArchiveHandler} />
            }
            <MeetingArchivePanel {...props}
                showImportArchiveView={isImportArchiveVisible()}
                showMeetingSelection={isMeetingTabSelected()}
                onSaveComplete={onSaveComplete}
                isOpen={isArchivePanelOpen}
                onDismiss={closeArchivePanel}
                selectedMeetings={selectedMeetings} />
        </ThemeProvider>;
    } else {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Meeting Archiving is not supported in this version of 360°')}</h3>
        </blockquote>;
    }
};

export default MeetingArchiving;